import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function SettingsDialog(props) {
  const [count, setCount] = React.useState(0);
  const [showDeclined, setShowDecl] = React.useState(false);

  const { handleClose, dialogOpen } = props;

  React.useEffect(() => {
    if (count === 0) {
      setShowDecl(props.showDecl);
    }
  },[props.showDecl,setShowDecl,count]);

  function saveData() {
    handleClose({ showDeclined });
    setCount(0);
  }

  function cancelData() {
    handleClose(null);
    setCount(0);
  }

  function clickShowDecl() {
    setCount(1);
    setShowDecl(!showDeclined);
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{props.text_settings}</DialogTitle>
      <DialogContent>
        <FormGroup>
          <FormControlLabel
            control=<Checkbox
              color="default"
              checked={showDeclined}
              onClick={clickShowDecl}
            />
            label={props.text_show_declined_speakers}
          />
        </FormGroup>

        
      </DialogContent>
      <DialogActions>
        <Button onClick={saveData}>{props.text_save}</Button>
        <Button onClick={cancelData}>{props.text_cancel}</Button>
      </DialogActions>
    </Dialog>
  );
}
