import * as React from "react";
import i18next from "i18next";
import TextFieldHint from "../TextFieldHint";
import LabelHint from "../LabelHint";
import TranslationInstruction from "./TranslationInstruction";
import { Button, ButtonGroup, Divider } from "@mui/material";

export default function TranslationSettings(props) {
  const {
    translation,
    translationUrl, setTranslationUrl,
    createTranslation, delTranslation,
    delayedCreation
  } = props;
  const [translationMode, setTranslationMode] = React.useState("url");
  const [translationState, setTranslationState] = React.useState(null);
  React.useEffect(() => {
    setTranslationMode(translation ? "rtmp" : "url");
    setTranslationState(translation);
  }, [translation]);

  return <div style={{ marginTop: 10, fontSize: 16 }}>
    <Divider style={{marginBottom:10}} />
    <LabelHint title="translation_settings" hint="translation_settings_hint" />
    <ButtonGroup style={{marginTop:10, marginBottom:10}}>
      <Button variant={translationMode === "url" ? "contained" : "outlined"} onClick={() => {
        setTranslationMode("url");
        delayedCreation && delayedCreation(0);
      }}>
        {i18next.t("translation_url_mode")}
      </Button>
      <Button variant={translationMode === "rtmp" ? "contained" : "outlined"} onClick={() => {
        setTranslationMode("rtmp");
        delayedCreation && delayedCreation(1);
      }}>
        {i18next.t("translation_kinescop")}
      </Button>
    </ButtonGroup>
    {translationMode === "url" && <TextFieldHint
      margin="dense"
      id="url"
      label={i18next.t("translation_url")}
      hint={i18next.t("translation_url_hint")}
      fullWidth
      variant="standard"
      value={translationUrl}
      onChange={e => setTranslationUrl(e.target.value)}
    />}
    {translationMode === "rtmp" && <div>
      {translationState && <TranslationInstruction translation={translationState} />}

      {!delayedCreation && !translationState && (
        <Button onClick={() => createTranslation(data => {
          setTranslationState(data);
          setTranslationUrl(data.live_url);
        })}>
          {i18next.t("translation_create")}
        </Button>
      )}
      {!delayedCreation && translationState && translationState.service_id && (
        <Button onClick={() => {
          delTranslation();
          setTranslationMode("url");
          setTranslationUrl(null);
          setTranslationState(null);
        }}>
          {i18next.t("translation_delete")}
        </Button>
      )}
      {delayedCreation && <div>{i18next.t("translation_delayed_creation")}</div>}
    </div>}
  </div>;
}
