import React from "react";
import "../css/style.css";

import { connect } from "react-redux";
import i18next from "i18next";
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { userActions } from "../_actions";
import { history } from "../_helpers";
import { userService } from "../_services";

class QrCodePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: "",
      isHasPermission: false,
      qrCode: "",
      pageText: "",
    };
  }

  componentDidMount() {
    let urls = window.location.href.split("/");

    let self = this;
    let qr_code = urls[urls.length - 1];

    this.setState({ qrCode: qr_code });

    let userDataStr = localStorage.getItem("user");
    let userDataObj = {};

    if (userDataStr) {
      try {
        userDataObj = JSON.parse(userDataStr);
      } catch (error) {
        alert(error);
      }
    }

    if (userDataObj && userDataObj.type) {
      self.sendChecking(qr_code);
    } else {
      this.setState({ isLoading: true });
      userService.getWrapper(
        "api/user",
        (data) => {
          if (data.data && data.data.type) {
            self.setState({ isLoading: false });
            self.sendChecking(qr_code);
          } else self.setState({ isHasPermission: false, isLoading: false });
        },
        (error) => {
          self.setState({
            isHasPermission: false,
            isLoading: false,
            error: "",
          });
          self.redirectLogin();
        },
        true
      );
    }
  }

  redirectLogin = () => {
    const src_url = window.location.pathname + window.location.search;
    localStorage.setItem("all_redirect", src_url);
    localStorage.setItem("all_redirect_count", "1");

    history.push("/login");
  };

  sendChecking = (qrCode) => {
    const src_url = window.location.pathname + window.location.search;

    let self = this;

    userService.getWrapper(
      "api/qr/action?uuid=" + qrCode,
      (data) => {
        console.log(JSON.stringify(data.data));

        if (data.data) {
          if (data.data.action == "redirect") {
            //window.location.href = data.data.url;
          } else if (data.data.action == "text") {
            self.setState({ pageText: data.data.html });
          }
        }
      },
      (error) => {
        alert(error);
      }
    );
  };

  //{!isHasPermission && <h3>{i18next.t("has_no_permission")}</h3>}

  render() {
    const self = this;
    const { isLoading, isHasPermission, error, qrCode, pageText } = this.state;

    return (
      <div className="layout">
        <Button
          variant="contained"
          onClick={() => history.push("/")}
          style={{ marginTop: 10 }}
        >
          {i18next.t("back")}
        </Button>
        <div className="layout__contains">
          <h2>
            {" "}
            {i18next.t("check_qrcode")} {qrCode}
          </h2>
        </div>

        <h1 dangerouslySetInnerHTML={{ __html: pageText }} />

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedQrCodePage = connect(mapStateToProps)(QrCodePage);
export { connectedQrCodePage as QrCodePage };
