import React from "react";
import "../css/style.css";

import { connect } from "react-redux";
import i18next from "i18next";
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { userActions } from "../_actions";
import { history } from "../_helpers";
import { userService } from "../_services";

const QuestToArray = (qDict) => {
  let keys = Object.keys(qDict);

  let qArray = [];
  for (let i = 0; i < keys.length; i++) {
    qArray.push({ ...qDict[keys[i]], key: keys[i] });
  }

  return qArray;
};

class CheckCodePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: "",
      isHasPermission: false,
      checkCode: "",
    };
  }

  componentDidMount() {
    let urls = window.location.href.split("/");

    let self = this;
    let check_code = urls[urls.length - 1];
    this.setState({checkCode:check_code});

    let userDataStr = localStorage.getItem("user");
    let userDataObj = {};

    if (userDataStr) {
      try {
        userDataObj = JSON.parse(userDataStr);
      } catch (error) {
        alert(error);
      }
    }

    if (userDataObj && userDataObj.type) {
      if (userDataObj.type == "org") {
        this.setState({ isHasPermission: true });
      }

      localStorage.setItem("all_redirect", "");
      localStorage.setItem("all_redirect_count", "");
    } else {
      this.setState({ isLoading: true });
      userService.getWrapper(
        "api/user",
        (data) => {
          if (data.data && data.data.type && data.data.type == "org") {
            self.setState({ isHasPermission: true, isLoading: false });
          } else self.setState({ isHasPermission: false, isLoading: false });
        },
        (error) => {
          self.setState({
            isHasPermission: false,
            isLoading: false,
            error: "",
          });
          self.redirectLogin();
        },
        true
      );
    }
  }

  showAns = () => {
    const { vkey, vid } = this.state;

    this.sendChecking(vid, vkey);
  };

  redirectLogin = () => {
    const src_url = window.location.pathname + window.location.search;
    localStorage.setItem("all_redirect", src_url);
    localStorage.setItem("all_redirect_count", "1");

    history.push("/login");
  };

  sendChecking = () => {
    const src_url = window.location.pathname + window.location.search;

    let self = this;
    //alert("SEND REQUEST FOR CHECKING");

    /*
    userService.getWrapper(
      "api/vote/result?signature=" + key + "&id=" + id,
      (data) => {
        if (data.data)
          this.setState({ answers: QuestToArray(data.data), error: "" });
        localStorage.setItem("navic_redirect", "");
        localStorage.setItem("navic_redirect_count", "");
      },
      (error) => {
        self.props.dispatch(userActions.set_error(error));
        if (localStorage.getItem("navic_redirect_count") == "") {
          this.setState({ error: "" });
          localStorage.setItem("navic_redirect", src_url);
          localStorage.setItem("navic_redirect_count", "1");
          history.push("/login");
        } else if (localStorage.getItem("navic_redirect_count") == "1") {
          localStorage.setItem("navic_redirect", src_url);
          localStorage.setItem("navic_user", "");
          localStorage.setItem("navic_pwd", "");
          localStorage.setItem("navic_redirect_count", "2");
          history.push("/login");
        } else {
          this.setState({
            error: i18next.t("incorrect_url_or_no_rules")
          });
          localStorage.setItem("navic_redirect", "");
          setTimeout(() => {
            localStorage.setItem("navic_redirect_count", "");
          }, 2000);
        }

        localStorage.setItem("navic_redirect_count", "1");
      }
    );
    */
  };

  render() {
    const self = this;
    const { isLoading, isHasPermission, error,checkCode } = this.state;

    // {this.props.conf_name}

    return (
      <div className="layout">
        <div className="layout__contains">
          <h2> {i18next.t("check_visit")} {checkCode}</h2>
          <Button
            variant="contained"
            onClick={() => history.push("/")}
            style={{ marginTop: 10 }}
          >
            {i18next.t("back")}
          </Button>
        </div>
        {isHasPermission && (
          <div>
            <Button
              variant="contained"
              onClick={self.showAns}
              style={{ marginBottom: 10 }}
            >
              {i18next.t("check_ticket")}
            </Button>
            {error && <div style={{ fontSize: 30 }}>{error}</div>}
          </div>
        )}
        {!isHasPermission && <h3>{i18next.t("has_no_permission")}</h3>}
        

        {isLoading && (
          <div className="shadow">
            <div className="shadow_circle">
              <CircularProgress />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedCheckCodePage = connect(mapStateToProps)(CheckCodePage);
export { connectedCheckCodePage as CheckCodePage };
