import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import i18next from "i18next";
import HelpIcon from "@material-ui/icons/Help";
import React from "react";

function LabelHint(props) {
  const { title, hint } = props;

  function SimpleDialog(props) {
    const { onClose, hint, open } = props;

    const handleClose = () => {
      onClose();
    };

    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle dangerouslySetInnerHTML={{ __html: i18next.t(hint) }}></DialogTitle>
      </Dialog>
    );
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <div style={{ flexDirection: "row", display: "flex" }}>
        <div style={{ marginTop: 4 }}>
          <label
            htmlFor="area1"
            className="form__label"
            title={i18next.t(hint)}
          >
            {i18next.t(props.title)}
          </label>
        </div>
        <div>
          <HelpIcon onClick={handleClickOpen} style={{ marginLeft: 4 }} />
        </div>
      </div>

      <SimpleDialog
        open={open}
        onClose={handleClose}
        hint={hint ?? "hint text"}
      />
    </>
  );
}

export default LabelHint;
