import * as React from "react";
import i18next from "i18next";
import TextFieldHint from "../TextFieldHint";

export default function TranslationInstruction(props) {
  const {translation} = props;

  return (
    <div style={{marginBottom:10}}>
      <TextFieldHint 
        disabled
        key="translation_stream_url"
        label={i18next.t("translation_stream_url")}
        hint={i18next.t("translation_stream_hint")}
        value={translation.stream_url}
      />
      <TextFieldHint 
        disabled
        key="translation_stream_key"
        label={i18next.t("translation_stream_key")}
        hint={i18next.t("translation_stream_hint")}
        value={translation.key}
      />
      <div style={{ marginTop: 10 }}>
        {i18next.t("url_live_translation")}:
      </div>
      <div style={{marginTop: 5}} className="common-link">
        <a href={translation.live_url} target="_blank">{translation.live_url}</a>
      </div>
    </div>
  );
}
