import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextFieldI18N from "../_components/TextFieldI18N";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TranslationSettings from "../_components/Translation/TranslationSettings";

export default function SectionDialog(props) {
  const [name, setName] = React.useState("");
  const [name_i18n, setNameI18N] = React.useState({});
  const [description_i18n, setDescI18N] = React.useState({});
  const [description, setDesc] = React.useState({});
  const [room, setRoom] = React.useState(-1);
  const [url, setUrl] = React.useState("");
  const [time, setTime] = React.useState("");
  const [duration, setDuration] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [createTranslationForNewSection, setCreateTranslationForNewSection] = React.useState(0);

  const {
    handleClose,
    dialogOpen,
    dName,
    dNameI18N,
    dDesc,
    dDescI18N,
    dTime,
    dDuration,
    dUrl,
    dRoom,
    rooms,
    text_cancel,
    text_save,
    placeholder_texts,
    isMultilang,
    langList,
    section,
    createTranslation,
    delTranslation
  } = props;

  React.useEffect(() => {
    if (count === 0) {
      setName(dName);
      setNameI18N(dNameI18N);
      setDesc(dDesc);
      setDescI18N(dDescI18N);
      setRoom(dRoom);
      setTime(dTime);
      setDuration(dDuration);
      setUrl(dUrl);
    }
  }, [count, dName, dDesc, dDescI18N, dNameI18N]);

  function saveData() {

    console.log("SSS",name);

    if (name !== "") {
      handleClose({
        name,
        name_i18n,
        description,
        description_i18n,
        room,
        start_time: time,
        duration,
        url,
        create_translation: createTranslationForNewSection,
      });
      setCount(0);
    }
  }

  function cancelData() {
    handleClose(null);
    setCount(0);
  }

  console.log('SECTION',section);

  return (
    <Dialog open={dialogOpen}>
      <DialogContent>
        <TextFieldI18N
          autoFocus
          margin="dense"
          id="name"
          label="Имя"
          fullWidth
          variant="standard"
          value={isMultilang ? name_i18n ?? {} : name}
          onChange={(e, tabName) => {
            if (isMultilang) {
              let tmpObj = name_i18n;
              tmpObj[tabName] = e.target.value;

              setNameI18N(tmpObj);
              setName(tmpObj["ru"]);
            } else {
              setName(e.target.value);
            }
            setCount(count + 1);
          }}
          langList={langList}
          isMultilang={isMultilang}
        />
        <TextFieldI18N
          autoFocus
          margin="dense"
          id="name"
          label={placeholder_texts["description"]}
          fullWidth
          variant="standard"
          value={isMultilang ? description_i18n ?? {} : description}
          onChange={(e, tabName) => {
            if (isMultilang) {
              let tmpObj = description_i18n;
              let tmpSimple = description;
              tmpObj[tabName] = e.target.value;

              tmpSimple = e.target.value;
              setDescI18N(tmpObj);

              setDesc(tmpObj["ru"]);
            } else {
              setDesc(e.target.value);
            }
            setCount(count + 1);
          }}
          langList={langList}
          isMultilang={isMultilang}
        />
        <div>
          <select onChange={(e) => setRoom(e.target.value)}>
            <option value={0} selected={room < 1}>
              {placeholder_texts["room"]}
            </option>
            {rooms.map((item, index) => (
              <option value={item.id} selected={room == item.id}>
                {item.number}
              </option>
            ))}
          </select>
        </div>
        {section && section.unsorted_by_time_lessons && (
          <TextField
            margin="dense"
            id="time"
            type="time"
            min="00:00"
            max="23:59"
            label={placeholder_texts["start_time"]}
            fullWidth
            variant="standard"
            value={time ? time : ""}
            onChange={(e) => {
              setTime(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {section && section.unsorted_by_time_lessons && (
          <TextField
            margin="dense"
            id="durarion"
            label={placeholder_texts["duration"]}
            fullWidth
            type="number"
            variant="standard"
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {<TranslationSettings
          translation={section ? section.translation : null}
          translationUrl={url} setTranslationUrl={value => setUrl(value)}
          delayedCreation={!section && (value => setCreateTranslationForNewSection(value))}
          createTranslation={afterCreate => createTranslation("section", section.id, afterCreate)}
          delTranslation={() => delTranslation("section", section.id)}
        />}
      </DialogContent>
      <DialogActions>
        <Button onClick={saveData}>{text_save}</Button>
        <Button onClick={cancelData}>{text_cancel}</Button>
      </DialogActions>
    </Dialog>
  );
}
